var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "w": "full"
    }
  }, [_c('c-flex', {
    attrs: {
      "mt": "20px"
    }
  }, [_c('c-form-control', {
    attrs: {
      "is-required": !!_vm.$v.ingredientName.$params.required,
      "is-invalid": _vm.$v.ingredientName.$invalid,
      "w": "590px"
    }
  }, [_c('c-form-label', [_vm._v("Nama Makanan")]), _c('c-input', {
    attrs: {
      "h": "62px",
      "type": "text"
    },
    model: {
      value: _vm.ingredientName,
      callback: function callback($$v) {
        _vm.ingredientName = $$v;
      },
      expression: "ingredientName"
    }
  }), !_vm.$v.ingredientName.required ? _c('c-form-helper-text', {
    attrs: {
      "color": "red.500",
      "font-size": "12px"
    }
  }, [_vm._v(" Harus diisi ")]) : _vm._e()], 1)], 1), _c('c-flex', {
    attrs: {
      "mt": "20px"
    }
  }, [_c('c-form-control', {
    attrs: {
      "is-required": !!_vm.$v.dose.$params.required,
      "is-invalid": _vm.$v.dose.$invalid,
      "w": "590px"
    }
  }, [_c('c-form-label', [_vm._v("Takaran")]), _c('c-input', {
    attrs: {
      "h": "62px",
      "type": "number"
    },
    model: {
      value: _vm.dose,
      callback: function callback($$v) {
        _vm.dose = _vm._n($$v);
      },
      expression: "dose"
    }
  }), !_vm.$v.dose.required ? _c('c-form-helper-text', {
    attrs: {
      "color": "red.500",
      "font-size": "12px"
    }
  }, [_vm._v(" Harus diisi ")]) : _vm._e(), !_vm.$v.dose.decimal ? _c('c-form-helper-text', {
    attrs: {
      "color": "red.500",
      "font-size": "12px"
    }
  }, [_vm._v(" Harus berupa angka ")]) : _vm._e()], 1)], 1), _c('c-flex', {
    attrs: {
      "mt": "20px"
    }
  }, [_c('c-form-control', {
    attrs: {
      "is-required": !!_vm.$v.doseUnit.$params.required,
      "is-invalid": _vm.$v.doseUnit.$invalid,
      "w": "590px"
    }
  }, [_c('c-form-label', [_vm._v("Nama Takaran")]), _c('c-input', {
    attrs: {
      "h": "62px",
      "type": "text"
    },
    model: {
      value: _vm.doseUnit,
      callback: function callback($$v) {
        _vm.doseUnit = $$v;
      },
      expression: "doseUnit"
    }
  }), !_vm.$v.doseUnit.required ? _c('c-form-helper-text', {
    attrs: {
      "color": "red.500",
      "font-size": "12px"
    }
  }, [_vm._v(" Harus diisi ")]) : _vm._e()], 1)], 1), _c('c-flex', {
    attrs: {
      "mt": "20px"
    }
  }, [_c('c-form-control', {
    attrs: {
      "is-required": !!_vm.$v.foodGroup.$params.required,
      "is-invalid": _vm.$v.foodGroup.$invalid,
      "w": "590px"
    }
  }, [_c('c-form-label', [_vm._v("Nama Golongan Makanan")]), _c('c-select', {
    attrs: {
      "h": "62px",
      "placeholder": "Nama Golongan Makanan"
    },
    model: {
      value: _vm.foodGroup,
      callback: function callback($$v) {
        _vm.foodGroup = $$v;
      },
      expression: "foodGroup"
    }
  }, _vm._l(_vm.groups, function (item) {
    return _c('option', {
      key: item,
      domProps: {
        "value": item
      }
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 0), !_vm.$v.foodGroup.required ? _c('c-form-helper-text', {
    attrs: {
      "color": "red.500",
      "font-size": "12px"
    }
  }, [_vm._v(" Harus diisi ")]) : _vm._e()], 1)], 1), _c('c-flex', {
    attrs: {
      "mt": "20px"
    }
  }, [_c('c-form-control', {
    attrs: {
      "is-required": !!_vm.$v.foodIngredient.$params.required,
      "is-invalid": _vm.$v.foodIngredient.$invalid,
      "w": "590px"
    }
  }, [_c('c-form-label', [_vm._v("Nama Bahan Dasar Makanan")]), _c('c-input', {
    attrs: {
      "h": "62px",
      "type": "text"
    },
    model: {
      value: _vm.foodIngredient,
      callback: function callback($$v) {
        _vm.foodIngredient = $$v;
      },
      expression: "foodIngredient"
    }
  }), !_vm.$v.foodIngredient.required ? _c('c-form-helper-text', {
    attrs: {
      "color": "red.500",
      "font-size": "12px"
    }
  }, [_vm._v(" Harus diisi ")]) : _vm._e()], 1)], 1), _c('c-flex', {
    attrs: {
      "mt": "20px"
    }
  }, [_c('c-form-control', {
    attrs: {
      "w": "full",
      "is-required": !!_vm.$v.photoUrl.$params.required,
      "is-invalid": _vm.$v.photoUrl.$invalid
    }
  }, [_c('c-form-label', [_vm._v("Gambar Makanan")]), _c('c-flex', {
    attrs: {
      "w": "full"
    }
  }, [_c('c-box', {
    attrs: {
      "border-width": "2px",
      "border-color": _vm.$v.photoUrl.$invalid ? '#e66673' : '#c4c4c4',
      "width": "180px",
      "height": "180px",
      "border-radius": "5px",
      "overflow": "hidden",
      "cursor": "pointer",
      "background-image": _vm.photoUrl == null ? 'url(/images/empty-photo.png)' : _vm.photoUrl,
      "background-size": _vm.photoUrl == null ? '63px 63px' : 'cover',
      "background-position": "center center",
      "background-repeat": "no-repeat",
      "margin-right": "30px"
    },
    on: {
      "click": function click($event) {
        return _vm.$refs.$input.click();
      }
    }
  }, [_c('input', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    ref: "$input",
    attrs: {
      "type": "file",
      "opacity": "0"
    },
    on: {
      "change": _vm.onChangePhoto
    }
  })]), _c('c-flex', {
    attrs: {
      "align-items": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "color": "gray.900",
      "font-size": "16px",
      "line-height": "24px",
      "fonr-family": "Roboto"
    }
  }, [_vm._v(" Foto maksimal 3 MB ")])], 1)], 1), !_vm.$v.photoUrl.required ? _c('c-form-helper-text', {
    attrs: {
      "color": "red.500",
      "font-size": "12px"
    }
  }, [_vm._v(" Harus diisi ")]) : _vm._e()], 1)], 1), _c('c-flex', {
    attrs: {
      "mt": "20px"
    }
  }, [_c('c-form-control', {
    attrs: {
      "is-required": !!_vm.$v.calories.$params.required,
      "is-invalid": _vm.$v.calories.$invalid,
      "w": "590px"
    }
  }, [_c('c-form-label', [_vm._v("Kalori")]), _c('c-input', {
    attrs: {
      "h": "62px",
      "type": "text"
    },
    model: {
      value: _vm.calories,
      callback: function callback($$v) {
        _vm.calories = $$v;
      },
      expression: "calories"
    }
  }), !_vm.$v.calories.required ? _c('c-form-helper-text', {
    attrs: {
      "color": "red.500",
      "font-size": "12px"
    }
  }, [_vm._v(" Harus diisi ")]) : _vm._e()], 1)], 1), _c('c-flex', {
    attrs: {
      "mt": "20px"
    }
  }, [_c('c-form-control', {
    attrs: {
      "is-required": !!_vm.$v.protein.$params.required,
      "is-invalid": _vm.$v.protein.$invalid,
      "w": "590px"
    }
  }, [_c('c-form-label', [_vm._v("Protein")]), _c('c-input', {
    attrs: {
      "h": "62px",
      "type": "text"
    },
    model: {
      value: _vm.protein,
      callback: function callback($$v) {
        _vm.protein = $$v;
      },
      expression: "protein"
    }
  }), !_vm.$v.protein.required ? _c('c-form-helper-text', {
    attrs: {
      "color": "red.500",
      "font-size": "12px"
    }
  }, [_vm._v(" Harus diisi ")]) : _vm._e()], 1)], 1), _c('c-flex', {
    attrs: {
      "mt": "20px"
    }
  }, [_c('c-form-control', {
    attrs: {
      "is-required": !!_vm.$v.fat.$params.required,
      "is-invalid": _vm.$v.fat.$invalid,
      "w": "590px"
    }
  }, [_c('c-form-label', [_vm._v("Lemak")]), _c('c-input', {
    attrs: {
      "h": "62px",
      "type": "text"
    },
    model: {
      value: _vm.fat,
      callback: function callback($$v) {
        _vm.fat = $$v;
      },
      expression: "fat"
    }
  }), !_vm.$v.fat.required ? _c('c-form-helper-text', {
    attrs: {
      "color": "red.500",
      "font-size": "12px"
    }
  }, [_vm._v(" Harus diisi ")]) : _vm._e()], 1)], 1), _c('c-flex', {
    attrs: {
      "mt": "20px"
    }
  }, [_c('c-form-control', {
    attrs: {
      "is-required": !!_vm.$v.carbohydrate.$params.required,
      "is-invalid": _vm.$v.carbohydrate.$invalid,
      "w": "590px"
    }
  }, [_c('c-form-label', [_vm._v("Karbohidrat")]), _c('c-input', {
    attrs: {
      "h": "62px",
      "type": "text"
    },
    model: {
      value: _vm.carbohydrate,
      callback: function callback($$v) {
        _vm.carbohydrate = $$v;
      },
      expression: "carbohydrate"
    }
  }), !_vm.$v.carbohydrate.required ? _c('c-form-helper-text', {
    attrs: {
      "color": "red.500",
      "font-size": "12px"
    }
  }, [_vm._v(" Harus diisi ")]) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }