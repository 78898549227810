<template>
  <c-box w="full">
    <c-flex mt="20px">
      <c-form-control
        :is-required="!!$v.ingredientName.$params.required"
        :is-invalid="$v.ingredientName.$invalid"
        w="590px"
      >
        <c-form-label>Nama Makanan</c-form-label>
        <c-input
          v-model="ingredientName"
          h="62px"
          type="text"
        />
        <c-form-helper-text
          v-if="!$v.ingredientName.required"
          color="red.500"
          font-size="12px"
        >
          Harus diisi
        </c-form-helper-text>
      </c-form-control>
    </c-flex>
    <c-flex mt="20px">
      <c-form-control
        :is-required="!!$v.dose.$params.required"
        :is-invalid="$v.dose.$invalid"
        w="590px"
      >
        <c-form-label>Takaran</c-form-label>
        <c-input
          v-model.number="dose"
          h="62px"
          type="number"
        />
        <c-form-helper-text
          v-if="!$v.dose.required"
          color="red.500"
          font-size="12px"
        >
          Harus diisi
        </c-form-helper-text>
        <c-form-helper-text
          v-if="!$v.dose.decimal"
          color="red.500"
          font-size="12px"
        >
          Harus berupa angka
        </c-form-helper-text>
      </c-form-control>
    </c-flex>
    <c-flex mt="20px">
      <c-form-control
        :is-required="!!$v.doseUnit.$params.required"
        :is-invalid="$v.doseUnit.$invalid"
        w="590px"
      >
        <c-form-label>Nama Takaran</c-form-label>
        <c-input
          v-model="doseUnit"
          h="62px"
          type="text"
        />
        <c-form-helper-text
          v-if="!$v.doseUnit.required"
          color="red.500"
          font-size="12px"
        >
          Harus diisi
        </c-form-helper-text>
      </c-form-control>
    </c-flex>
    <c-flex mt="20px">
      <c-form-control
        :is-required="!!$v.foodGroup.$params.required"
        :is-invalid="$v.foodGroup.$invalid"
        w="590px"
      >
        <c-form-label>Nama Golongan Makanan</c-form-label>
        <c-select
          v-model="foodGroup"
          h="62px"
          placeholder="Nama Golongan Makanan"
        >
          <option
            v-for="item in groups"
            :key="item"
            :value="item"
          >
            {{ item }}
          </option>
        </c-select>
        <c-form-helper-text
          v-if="!$v.foodGroup.required"
          color="red.500"
          font-size="12px"
        >
          Harus diisi
        </c-form-helper-text>
      </c-form-control>
    </c-flex>
    <c-flex mt="20px">
      <c-form-control
        :is-required="!!$v.foodIngredient.$params.required"
        :is-invalid="$v.foodIngredient.$invalid"
        w="590px"
      >
        <c-form-label>Nama Bahan Dasar Makanan</c-form-label>
        <c-input
          v-model="foodIngredient"
          h="62px"
          type="text"
        />
        <c-form-helper-text
          v-if="!$v.foodIngredient.required"
          color="red.500"
          font-size="12px"
        >
          Harus diisi
        </c-form-helper-text>
      </c-form-control>
    </c-flex>
    <c-flex mt="20px">
      <c-form-control
        w="full"
        :is-required="!!$v.photoUrl.$params.required"
        :is-invalid="$v.photoUrl.$invalid"
      >
        <c-form-label>Gambar Makanan</c-form-label>
        <c-flex w="full">
          <c-box
            border-width="2px"
            :border-color="$v.photoUrl.$invalid ? '#e66673' : '#c4c4c4'"
            width="180px"
            height="180px"
            border-radius="5px"
            overflow="hidden"
            cursor="pointer"
            :background-image="
              photoUrl == null ? 'url(/images/empty-photo.png)' : photoUrl
            "
            :background-size="photoUrl == null ? '63px 63px' : 'cover'"
            background-position="center center"
            background-repeat="no-repeat"
            margin-right="30px"
            @click="$refs.$input.click()"
          >
            <input
              ref="$input"
              v-chakra
              type="file"
              opacity="0"
              @change="onChangePhoto"
            >
          </c-box>
          <c-flex align-items="center">
            <c-text
              color="gray.900"
              font-size="16px"
              line-height="24px"
              fonr-family="Roboto"
            >
              Foto maksimal 3 MB
            </c-text>
          </c-flex>
        </c-flex>
        <c-form-helper-text
          v-if="!$v.photoUrl.required"
          color="red.500"
          font-size="12px"
        >
          Harus diisi
        </c-form-helper-text>
      </c-form-control>
    </c-flex>
    <c-flex mt="20px">
      <c-form-control
        :is-required="!!$v.calories.$params.required"
        :is-invalid="$v.calories.$invalid"
        w="590px"
      >
        <c-form-label>Kalori</c-form-label>
        <c-input
          v-model="calories"
          h="62px"
          type="text"
        />
        <c-form-helper-text
          v-if="!$v.calories.required"
          color="red.500"
          font-size="12px"
        >
          Harus diisi
        </c-form-helper-text>
      </c-form-control>
    </c-flex>
    <c-flex mt="20px">
      <c-form-control
        :is-required="!!$v.protein.$params.required"
        :is-invalid="$v.protein.$invalid"
        w="590px"
      >
        <c-form-label>Protein</c-form-label>
        <c-input
          v-model="protein"
          h="62px"
          type="text"
        />
        <c-form-helper-text
          v-if="!$v.protein.required"
          color="red.500"
          font-size="12px"
        >
          Harus diisi
        </c-form-helper-text>
      </c-form-control>
    </c-flex>
    <c-flex mt="20px">
      <c-form-control
        :is-required="!!$v.fat.$params.required"
        :is-invalid="$v.fat.$invalid"
        w="590px"
      >
        <c-form-label>Lemak</c-form-label>
        <c-input
          v-model="fat"
          h="62px"
          type="text"
        />
        <c-form-helper-text
          v-if="!$v.fat.required"
          color="red.500"
          font-size="12px"
        >
          Harus diisi
        </c-form-helper-text>
      </c-form-control>
    </c-flex>
    <c-flex mt="20px">
      <c-form-control
        :is-required="!!$v.carbohydrate.$params.required"
        :is-invalid="$v.carbohydrate.$invalid"
        w="590px"
      >
        <c-form-label>Karbohidrat</c-form-label>
        <c-input
          v-model="carbohydrate"
          h="62px"
          type="text"
        />
        <c-form-helper-text
          v-if="!$v.carbohydrate.required"
          color="red.500"
          font-size="12px"
        >
          Harus diisi
        </c-form-helper-text>
      </c-form-control>
    </c-flex>
  </c-box>
</template>

<script>
import debounce from 'lodash/debounce'
import { required, decimal } from 'vuelidate/lib/validators'
import equal from 'fast-deep-equal'

export default {
  name: 'SUGlossaryForms',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      ingredientName: null,
      dose: null,
      doseUnit: null,
      foodGroup: null,
      foodIngredient: null,
      photoUrl: null,
      photoFile: null,
      calories: null,
      protein: null,
      fat: null,
      carbohydrate: null,
      // ---
      groups: [],
    }
  },
  computed: {
    value_() {
      return {
        ingredientName: this.ingredientName,
        foodIngredient: this.foodIngredient,
        foodGroup: this.foodGroup,
        dose: this.dose,
        doseUnit: this.doseUnit,
        photoUrl: this.photoUrl,
        photoFile: this.photoFile,
        calories: this.calories,
        protein: this.protein,
        fat: this.fat,
        carbohydrate: this.carbohydrate,
      }
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val, old) {
        if (equal(val, old) || val == null) return

        this.ingredientName = val.ingredientName
        this.dose = val.dose
        this.doseUnit = val.doseUnit
        this.foodGroup = val.foodGroup
        if (this.groups.includes(this.foodGroup) === false && this.foodGroup != null) {
          this.groups.push(this.foodGroup)
        }
        this.foodIngredient = val.foodIngredient
        this.photoUrl = val.photoUrl
        this.calories = val.calories
        this.protein = val.protein
        this.fat = val.fat
        this.carbohydrate = val.carbohydrate
      },
    },
    photoFile(file) {
      if (this.photoUrl?.startsWith('blob')) {
        URL.revokeObjectURL(this.photoUrl)
      }
      this.photoUrl = URL.createObjectURL(file)
    },
    value_: {
      immediate: false,
      deep: true,
      handler(val, old) {
        if (equal(val, old)) return
        if (equal(val, this.value)) return

        this.$emit('input', val)
        this.emitChanged(val)
      },
    },
  },
  async mounted() {
    this.groups = await this.$store
      .dispatch('suGlossary/list')
      .then((r) => r.filter)
    this.foodGroup = this.value?.foodGroup
  },
  methods: {
    onChangePhoto($event) {
      let file = $event.target.files[0]
      this.photoFile = file
    },
    emitChanged: debounce(function(val) {
      this.$emit('change', val)
    }, 100),
  },
  validations: {
    ingredientName: { required },
    dose: { required, decimal },
    doseUnit: { required },
    foodGroup: { required },
    foodIngredient: { required },
    photoUrl: { required },
    calories: { required, decimal },
    protein: { required, decimal },
    fat: { required, decimal },
    carbohydrate: { required, decimal },
  },
}
</script>
