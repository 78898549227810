import { render, staticRenderFns } from "./forms.vue?vue&type=template&id=55a45956&"
import script from "./forms.vue?vue&type=script&lang=js&"
export * from "./forms.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CFormLabel: require('@chakra-ui/vue').CFormLabel, CInput: require('@chakra-ui/vue').CInput, CFormHelperText: require('@chakra-ui/vue').CFormHelperText, CFormControl: require('@chakra-ui/vue').CFormControl, CFlex: require('@chakra-ui/vue').CFlex, CSelect: require('@chakra-ui/vue').CSelect, CBox: require('@chakra-ui/vue').CBox, CText: require('@chakra-ui/vue').CText})
